import 'css/base/variables.css'; // variaveis
import 'css/vendor/tippy-js.css';
import 'tippy.js/animations/shift-away-subtle.css';
import "css/index.css"; // entrada





// if (import.meta.hot) {
// 	// HMR code
// 	// console.log('hot', import.meta.hot)
// 	// console.log('hot', import.meta)

// 	import.meta.hot.on('vite:ws:connect', (e) => {
// 		console.log('vite:ws:connect')

// 	})
// 	// import.meta.hot.on('vite:afterUpdate', (e) => {
// 	// 	console.log('vite:afterUpdate')
// 	// })
// }
document
	.addEventListener("DOMContentLoaded", () => {

		document.body.classList.add("DOMContentLoaded")
		// console.log("DOMContentLoaded")
	}
	)